//#region Imports

import { Injectable } from '@angular/core';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class ErrorService {

  //#region Methods

  public getErrorMessage(error: any): string {
    if (typeof error === 'string')
      return error;

    const { message, status } = typeof error === 'function' ? error().error : error?.error;

    if (status >= 500 && status <= 599)
      return message;

    if (!Array.isArray(message)) {
      if (typeof message === 'string' && message.includes('Cannot'))
        return message;

      return message;
    } else return message[0];
  }

  //#endregion

}
