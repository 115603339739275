//#region Imports

import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

//#endregion

@Injectable()
export class LoadingService {
  constructor(
    private readonly loading: NgxSpinnerService,
  ) {}

  //#region Public Properties

  public async show(name?: string): Promise<boolean> {
    await this.loading.show(name);

    return true;
  }

  public async hide(name?: string): Promise<boolean> {
    await this.loading.hide(name);

    return false;
  }

  //#endregion
}
