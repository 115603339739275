//#region Imports

import { Component, EventEmitter, Input, Output } from '@angular/core';

//#endregion

@Component({
  selector: 'app-menu-visitor',
  templateUrl: './menu-visitor.component.html',
  styleUrls: ['./menu-visitor.component.scss'],
})
export class MenuVisitorComponent {

  //#region Properties

  @Input()
  public open: boolean = false;

  @Output()
  public close: EventEmitter<void> = new EventEmitter<void>();

  //#endregion

}
