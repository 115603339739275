//#region Imports

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetManyProxy } from '../../models/proxies/base/get-many.proxy';
import { createCrudUrl, CrudRequestParams } from '../../utils/crud-options';
import { environment } from '../../../environments/environment';
import { CreateUserPayload } from '../../models/payloads/create-user.payload';
import { UpdateUserPayload } from '../../models/payloads/update-user.payload';
import { UserProxy } from '../../models/proxies/user.proxy';
import { AsyncResult } from '../../modules/http/models/async-result';
import { HttpService } from '../../modules/http/services/http.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class UserInteractor {

  //#region Constructor

  constructor(
    private readonly httpService: HttpService,
  ) { }

  //#endregion

  //#region Methods

  public async getAll(crudOptions?: CrudRequestParams<UserProxy>): Promise<GetManyProxy<UserProxy>> {
    const url = createCrudUrl<UserProxy>(environment.routes.user.base, crudOptions);

    return await this.httpService.get<UserProxy[]>(url);
  }

  public async getMe(): Promise<AsyncResult<UserProxy>> {
    return await this.httpService.get<UserProxy>(environment.routes.user.getMe);
  }

  public async create(payload: CreateUserPayload): Promise<AsyncResult<UserProxy>> {
    const url = environment.routes.user.base;

    return await this.httpService.post<UserProxy>(url, payload);
  }

  public async update(id: number, payload: Partial<UpdateUserPayload>): Promise<AsyncResult<UserProxy>> {
    const url = environment.routes.user.byId.replace('{userId}', String(id));

    return await this.httpService.put<UserProxy>(url, payload);
  }

  public async delete(id: number, password: string): Promise<AsyncResult<UserProxy>> {
    const url = environment.routes.user.byId.replace('{userId}', String(id));

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        password
      },
    };

    return await this.httpService.delete<UserProxy>(url, options);
  }

  //#endregion
}
