//#region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconModule } from '../icon/icon.module';
import { MenuVisitorComponent } from './menu-visitor.component';

//#endregion

@NgModule({
  imports: [
    CommonModule,
    RouterLink,
    IconModule,
  ],
  declarations: [
    MenuVisitorComponent,
  ],
  exports: [
    MenuVisitorComponent,
  ],
})
export class MenuVisitorModule {}
