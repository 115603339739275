//#region Imports

import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { AuthenticationInteractor } from '../../interactors/authentication/authentication.interactor';
import { LoginPayload } from '../../models/payloads/login.payload';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { ErrorService } from '../error/error.service';
import { StorageService } from '../storage/storage.service';
import { UserService } from '../user/user.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  //#region Constructor

  constructor(
    private readonly userService: UserService,
    private readonly errorService: ErrorService,
    private readonly interactor: AuthenticationInteractor,
    private readonly storageService: StorageService,
  ) { }

  //#endregion

  //#region Public Methods

  public async getUserFromStorage(): Promise<UserProxy | null> {
    const { success, error } = await this.storageService.get<UserProxy>(environment.keys.userInformation);

    if (!success)
      throw new Error(error?.message);

    return success;
  }

  public async getUserTokenFromStorage(): Promise<TokenProxy | null> {
    const { error, success } = await this.storageService.get<TokenProxy>(environment.keys.userToken);

    if (!success)
      throw new Error(error?.message);

    return success;
  }

  public async isLogged(): Promise<boolean> {
    return !!await this.getUserFromStorage().catch(() => null);
  }

  public async logout(): Promise<void> {
    await this.storageService.clear();
    this.userService.clearUser();
  }

  public async login(payload: LoginPayload): Promise<UserProxy | null> {
    const { error, success: tokenProxy } = await this.interactor.login(payload);

    if (error)
      throw this.errorService.getErrorMessage(error);

    await this.saveUserToken(tokenProxy);

    return this.userService.getUser();
  }

  public async getUserInformationAndUpdateLoggedUser(): Promise<void> {
    const { error: errorUser, success: user } = await this.interactor.getMe();

    if (errorUser) {
      await this.storageService.clear();
      throw this.errorService.getErrorMessage(errorUser);
    }

    try {
      if (user)
        await this.updateLoggedUser(user);
    } catch (error) {
      throw this.errorService.getErrorMessage(error);
    }
  }

  public async updateLoggedUser(user: UserProxy): Promise<void> {
    const { error: errorOnSaveUser } = await this.storageService.set(environment.keys.userInformation, user);

    if (errorOnSaveUser) {
      await this.storageService.clear();
      throw new Error(errorOnSaveUser.message);
    }

    this.userService.setUser(user);
  }

  //#endregion

  //#region Private Methods

  private async saveUserToken(token?: TokenProxy): Promise<void> {
    const { error: errorOnSaveToken } = await this.storageService.set(environment.keys.userToken, token);

    if (errorOnSaveToken)
      throw new Error(errorOnSaveToken.message);

    await this.getUserInformationAndUpdateLoggedUser();
  }

  //#endregion

}
