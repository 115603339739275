//#region Imports

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { TokenProxy } from '../models/proxies/token.proxy';
import { UserProxy } from '../models/proxies/user.proxy';
import { StorageService } from '../services/storage/storage.service';
import { UserService } from '../services/user/user.service';

//#endregion

export const AuthGuard = async (route: ActivatedRouteSnapshot): Promise<any> => {

  const storage = inject(StorageService);
  const userService = inject(UserService);
  const router = inject(Router);

  const { unprotectedRoute, protectedRoute, routeToRedirect, protectedTo } = route.data || {};

  if (!routeToRedirect)
    return true;

  const token = await storage.get<TokenProxy>(environment.keys.userToken).then(result => result.success);
  const { success: user } = await storage.get<UserProxy>(environment.keys.userInformation);
  let userCanAccessByRole: boolean = true;

  if (user)
    userService.setUser(user);

  if (protectedTo?.length) {
    if (!user?.roles?.some(value => protectedTo.includes(value)))
      userCanAccessByRole = false;
  }

  if (token && protectedRoute && userCanAccessByRole)
    return true;

  if (!token && unprotectedRoute && userCanAccessByRole)
    return true;

  return void await router.navigate([routeToRedirect]);
};
