//#region Imports

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuVisitorModule } from './components/menu-visitor/menu-visitor.module';
import { StorageDrivers } from './models/enums/store-drivers.enum';
import { HttpModule } from './modules/http/http.module';
import { BaseUrlInterceptor } from './modules/http/interceptors/base-url.interceptor';
import { BearerTokenInterceptor } from './modules/http/interceptors/bearer-token.interceptor';
import { RefreshTokenInterceptor } from './modules/http/interceptors/refresh-token.interceptor';
import { RetryInterceptor } from './modules/http/interceptors/retry.interceptor';
import { LoadingService } from './services/loading/loading.service';

registerLocaleData(localeEn, 'en');

//#endregion

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    MenuVisitorModule,
    BrowserAnimationsModule,
    NgxSpinnerModule.forRoot({
      type: 'ball-clip-rotate',
    }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    HttpModule.withConfig({
      baseUrl: environment.apiBaseUrl,
      bearerTokenKey: environment.keys.userToken,
    }),
    IonicStorageModule.forRoot({
      dbKey: '__evespdb_key',
      storeName: '__evespdb',
      driverOrder: [
        StorageDrivers.SQLITE,
        StorageDrivers.IndexedDB,
        StorageDrivers.LocalStorage,
      ],
    }),
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    LoadingService,
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BearerTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    LoadingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
