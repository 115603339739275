//#region Imports

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CrudRequestParams } from '../../utils/crud-options';
import { environment } from '../../../environments/environment';
import { UserInteractor } from '../../interactors/user/user.interactor';
import { CreateUserPayload } from '../../models/payloads/create-user.payload';
import { UpdateUserPayload } from '../../models/payloads/update-user.payload';
import { UserProxy } from '../../models/proxies/user.proxy';
import { ErrorService } from '../error/error.service';
import { StorageService } from '../storage/storage.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class UserService {

  //#region Constructor

  constructor(
    private readonly interactor: UserInteractor,
    private readonly errorService: ErrorService,
    private readonly storageService: StorageService
  ) { }

  //#endregion

  //#region Properties

  private readonly userSubject: BehaviorSubject<UserProxy> = new BehaviorSubject<UserProxy>(this.getUserResetInfo());

  //#endregion

  //#region Methods

  public getUser(): UserProxy {
    return this.userSubject.getValue();
  }

  public getUser$(): Observable<UserProxy> {
    return this.userSubject.asObservable();
  }

  public setUser(user: UserProxy): void {
    this.userSubject.next(user);
    this.storageService.set(environment.keys.userInformation, user);
  }

  public clearUser(): void {
    this.userSubject.next(this.getUserResetInfo());
  }

  public async get(crudOptions?: CrudRequestParams<UserProxy>): Promise<UserProxy[]> {
    const { success, error } = await this.interactor.getAll(crudOptions);

    if (!success)
      throw this.errorService.getErrorMessage(error);

    return Array.isArray(success) ? success : success.data;
  }

  public async getMe(): Promise<UserProxy> {
    const { success, error } = await this.interactor.getMe();

    if (!success)
      throw this.errorService.getErrorMessage(error);

    return success;
  }

  public async update(id: number, payload: Partial<UpdateUserPayload>): Promise<UserProxy> {
    const { success, error } = await this.interactor.update(id, payload);

    if (!success)
      throw this.errorService.getErrorMessage(error);

    return success;
  }

  public async create(payload: CreateUserPayload): Promise<UserProxy> {
    const { success: createdUser, error } = await this.interactor.create(payload);

    if (!createdUser)
      throw this.errorService.getErrorMessage(error);

    return createdUser;
  }

  public async delete(id: number, password: string): Promise<void> {
    const { error } = await this.interactor.delete(id, password);

    if (error)
      throw this.errorService.getErrorMessage(error);
    
  }

  //#endregion

  //#region Private Methods

  private getUserResetInfo(): UserProxy {
    return {
      id: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
      name: '',
      email: '',
      companyId: 0,
      image: '',
      roles: [],
    }
  }

  //#endregion

}
