<div class="menu" [class.open]="open">
  <div class="close-btn" (click)="close.emit()">
    <app-icon src="assets/icons/close.svg" alt="Close"></app-icon>
  </div>

  <div class="links">
    <a href="/dash/home">Home</a>
    <a href="/">Services</a>
    <a href="/about">About</a>
    <a href="/login">Contacts</a>
    <a href="/info/faq">FAQ</a>
  </div>

  <div class="social">
    <span>Follow us</span>

    <div class="social--links">
      <app-icon src="assets/icons/linkedin.svg"></app-icon>
      <app-icon src="assets/icons/twitter.svg"></app-icon>
      <app-icon src="assets/icons/facebook.svg"></app-icon>
    </div>
  </div>
</div>
