//#region Imports

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class MenuService {

  //#region Properties

  private readonly menuVisitorOpenStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  //#endregion

  //#region Functions

  public getCurrentMenuVisitorOpenStatus$(): Observable<boolean> {
    return this.menuVisitorOpenStatus$.asObservable();
  }

  public setMenuVisitorOpenStatus(status: boolean): void {
    this.menuVisitorOpenStatus$.next(status);
  }

  //#endregion

}
