//#region Imports

import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuService } from './services/menu/menu.service';

//#endregion

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {

  //#region Constructor

  constructor(
    private readonly menuService: MenuService,
  ) {
    this.menuVisitorSubscription = this.menuService.getCurrentMenuVisitorOpenStatus$().subscribe(value => {
      this.openMenuVisitor = value;
    });
  }

  //#endregion

  //#region Properties

  public openMenuVisitor: boolean = false;

  public menuVisitorSubscription: Subscription;

  //#endregion

  //#region Functions

  public ngOnDestroy(): void {
    this.menuVisitorSubscription?.unsubscribe();
  }

  public updateMenuVisitorOpenStatus(status: boolean): void {
    this.menuService.setMenuVisitorOpenStatus(status);
  }

  //#endregion

}
