//#region Imports

import { routes } from './routes';

//#endregion

export const environment = {
  production: false,
  apiBaseUrl: 'https://api-dev.eve-sp.ligafacens.com',
  routes,
  config: {
    redirectToWhenAuthenticated: '/dash/home',
    redirectToWhenUnauthenticated: '/login',
  },
  keys: {
    userToken: 'USER_TOKEN',
    userInformation: 'USER_INFO',
  },
};
