export const routes = {
  authentication: {
    login: '/auth/local',
    refreshToken: '/auth/refresh',
  },
  storeCategories: {
    base: '/store/categories',
    byId: '/store/categories/{categoryId}',
  },
  storeOrder: {
    base: '/store/orders',
    byId: '/store/orders/{orderId}',
  },
  storeOrderProduct: {
    base: '/store/orders/{orderId}/products',
    byId: '/store/orders/{orderId}/products/{orderProductId}',
  },
  storeOrderLogs: {
    base: '/store/orders/{orderId}/logs',
    byId: '/store/orders/{orderId}/logs/{logId}',
  },
  companies: {
    base: '/companies',
    byId: '/companies/{companyId}',
  },
  companiesAddresses: {
    base: '/companies/{companyId}/addresses',
    byId: '/companies/{companyId}/addresses/{addressId}',
  },
  location: {
    states: '/location/states',
    cities: '/location/state/{stateCode}/cities',
  },
  medias: {
    images: '/medias/presigned/images',
    videos: '/medias/presigned/videos',
  },
  products: {
    base: '/store/products',
    byId: '/store/products/{productId}',
    detailed: '/store/products/detailed',
  },
  productFiles: {
    base: '/store/products/{productId}/files',
    byId: '/store/products/{productId}/files/{fileId}',
    bulk: '/store/products/{productId}/files/bulk',
  },
  productCategories: {
    base: '/store/products/{productId}/categories',
    byId: '/store/products/{productId}/categories/{categoryId}',
    bulk: '/store/products/{productId}/categories/bulk',
  },
  productsAnalytics: {
    stockLow: '/store/products/analytics/stock/low',
    stockHighoutput: '/store/products/analytics/stock/highoutput',
    stockIo: '/store/products/analytics/stock/io?startAt={startAt}&endAt={endAt}',
    stockExits: '/store/products/analytics/stock/exits',
  },
  user: {
    base: '/users',
    byId: '/users/{userId}',
    getMe: '/users/me',
  },
  userCarts: {
    base: '/users/carts',
    byId: '/users/carts/{cartId}',
    getMe: '/users/carts/me',
    shipping: '/users/carts/me/shipping',
    createOrderBasedOnCart: '/users/carts/order/{cartId}'
  },
  userCartsProducts: {
    base: '/users/carts/products',
    byId: '/users/carts/products/{productId}',
  },
  stockTransactions: {
    base: '/store/products/{productId}/stock/transactions',
    byId: '/store/products/{productId}/stock/transactions/{transactionId}'
  }
};
