//#region Imports

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import decode from 'jwt-decode';

import { BehaviorSubject, firstValueFrom, Observable, from as fromPromise } from 'rxjs';

import { catchError, concatAll, filter, map, take } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { TokenProxy } from '../../../models/proxies/token.proxy';
import { AuthService } from '../../../services/auth/auth.service';
import { StorageService } from '../../../services/storage/storage.service';

//#endregion

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  constructor(
    private readonly storage: StorageService,
    private readonly router: Router,
    private readonly authenticationService: AuthService,
  ) { }

  //#region Properties

  private readonly refreshState$ = new BehaviorSubject<{
    refreshing: boolean;
    token?: TokenProxy;
  }>({ refreshing: false });

  private sessionDidExpire: boolean = false;

  //#endregion

  //#region Methods

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return fromPromise(this.canPerformRequest()).pipe(
      map(canPerform => {
        if (!canPerform) {
          throw new HttpErrorResponse({
            error: {
              message: 'A sua sessão expirou, você precisa logar novamente.',
            },
            status: 401,
          });
        }

        this.sessionDidExpire = false;

        return next.handle(req);
      }),
      concatAll(),
      catchError(error => {
        if (error.status !== 401)
          throw error;

        if (req.url.includes('/auth/local'))
          throw error;

        this.authenticationService.logout()
          .then(async () => {
            if (this.sessionDidExpire)
              return;

            this.sessionDidExpire = true;

            new HttpErrorResponse({
              error: {
                message: 'A sua sessão expirou, você precisa logar novamente.',
              },
              status: 401,
            });

            await this.router.navigateByUrl(environment.config.redirectToWhenUnauthenticated);
          });

        throw error;
      }),
    );
  }

  //#endregion

  //#region Private Methods

  /**
   * Método que realiza a renovação do token de autenticação atual utilizando o token de atualização
   *
   * @param refreshToken O token de renovação
   */
  private async tryRefreshToken(refreshToken: string): Promise<TokenProxy | undefined> {
    if (this.refreshState$.value.refreshing) {
      const state = await firstValueFrom(this.refreshState$.pipe(filter(x => !x.refreshing)).pipe(take(1)));
      if (state.token)
        return state.token;
    }

    this.refreshState$.next({ refreshing: true });

    const proxy: TokenProxy | undefined = await fetch(
      environment.apiBaseUrl + environment.routes.authentication.refreshToken,
      {
        method: 'POST',
        headers: {
          Authorization: refreshToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
      .then(async result => result.ok ? await result.json() : undefined)
      .catch(() => undefined);

    this.refreshState$.next({ refreshing: false, token: proxy });

    return proxy;
  }

  /**
   * Verifica se o token JWT está expirado
   *
   * @param token O token JWT
   * @param maxExpiresDate A data máxima
   */
  private isTokenExpired(token: string, maxExpiresDate: number): boolean {
    const jwtPayload: { exp: number } = decode(token);

    return maxExpiresDate >= +new Date(jwtPayload.exp * 1000);
  }

  private async canPerformRequest(): Promise<boolean> {
    const { success: token } = await this.storage.get<TokenProxy>(environment.keys.userToken);

    // Se não temos um token, continuaremos a requisição mesmo assim
    if (!token || !token.token)
      return true;

    const fiveSecondsInMilliseconds = 1_000 * 5;
    const maxSafeExpiresDate = +new Date() + fiveSecondsInMilliseconds;

    // Se o token não está expirado, continua a solicitação
    if (!this.isTokenExpired(token.token, maxSafeExpiresDate))
      return true;

    // Se o refresh token está expirado, teremos que forçar o usuário a relogar
    if (!token.refreshToken || this.isTokenExpired(token.refreshToken, maxSafeExpiresDate))
      return false;

    const proxy = await this.tryRefreshToken(token.refreshToken);

    if (!proxy)
      return false;

    await this.storage.set(environment.keys.userToken, proxy);

    return true;
  }

  //#endregion

}
