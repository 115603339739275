//#region Imports

import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthGuard } from './guards/auth.guard';
import { UserRolesEnum } from './models/enums/user-roles.enum';

//#endregion

export const unAuthenticatedRoute: Partial<Route> = {
  canActivate: [AuthGuard],
  data: {
    routeToRedirect: environment.config.redirectToWhenAuthenticated,
    unprotectedRoute: true,
  },
};

export const authenticatedRoute: Partial<Route> = {
  canActivate: [AuthGuard],
  data: {
    routeToRedirect: environment.config.redirectToWhenUnauthenticated,
    protectedRoute: true,
  },
};

export const authenticatedRouteForSupplier: Partial<Route> = {
  canActivate: [AuthGuard],
  data: {
    routeToRedirect: environment.config.redirectToWhenAuthenticated,
    protectedRoute: true,
    protectedTo: [UserRolesEnum.SUPPLIER_AIRCRAFT_PART, UserRolesEnum.SUPPLIER_MULTIPART],
  },
};

export const authenticatedRouteForOperator: Partial<Route> = {
  canActivate: [AuthGuard],
  data: {
    routeToRedirect: environment.config.redirectToWhenAuthenticated,
    protectedRoute: true,
    protectedTo: [UserRolesEnum.OPERATOR],
  },
};

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule), ...unAuthenticatedRoute },
  { path: 'dash', loadChildren: () => import('./pages/dash/dash.module').then(m => m.DashModule), ...authenticatedRoute },
  { path: 'info', loadChildren: () => import('./pages/info/info.module').then(m => m.InfoModule) },
  { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
  { path: 'terms', loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule) },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
